import React from "react";
import {Link} from "react-router-dom";
import Request from "../components/Request/request";

class Tashkenttour extends React.Component{
    render() {
        return(
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/tash_tour.png" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i>  Tashkent</a></li>
                                    </ul>
                                    <div className="blog_details">

                                        <p>
                                            <p> <strong>Tashkent city tour </strong>   </p>
                                            <p>Today you will explore the capital city Tashkent with our experienced tour guide. After breakfast meet your guide and driver at the lobby and start the full day city tour. </p>
                                            <p>Places to visit: Hazrati Imam complex to see the oldest Qur’an in the world, Chorsu Bazar, Tashkent subway stations, Independence square, Amir Temur square and Central Asian Plov center. At the end of the trip drive back to your hotel. </p>
                                           </p>
                                            
   
                                           <table class="table">
 <tbody>
   <tr>
     <th scope="row">Price for 1 person</th>
     <td>$75 USD </td>
   </tr>
   <tr>
     <th scope="row">Price for 2 person</th>
     <td>$40 USD per person </td>
    
   </tr>
   <tr>
     <th scope="row">Price for 3 person</th>
     <td>$30 USD per person</td>
     
   </tr>
 </tbody>
</table>
<p>Prices are for  <strong> 2024 in USD  </strong> per person. <strong> Discounted prices  </strong> for larger groups are available on request.</p>

<div className="quote-wrapper">
                                            <div className="quotes">
                                                <strong> Tour Cost Includes:  </strong><br/><br/>
                                                <strong>1.	Experienced English-speaking tour guide;</strong> <br/>
                                                <strong>2.	Bottle of water</strong> <br/>
                                               <br/>
                                               
                                                <b> Tour Cost Does Not Include:</b><br/><br/>
                                                <strong>1.	Meals; </strong> <br/>
                                                <strong>2.	Entrance tickets; </strong> <br/>
                                                <strong>3.	Transportation (is available for an extra cost of $50 USD); </strong> <br/>
                                                <strong>4.	Any services not included in the above itinerary. </strong> <br/>
                                            </div>
                                        </div>

                                    <p> <strong> CITY TOUR NOTES </strong></p>
                                    <p>The tour is private with your personal guide. There will be no other people joining the tour. The starting time of the tour is up to you and may vary from 09.00 am to 16.00 pm.</p>
                                    <p>Please note that if you are booking this tour 24 hours before the tour starts, definitely message us on WhatsApp at <a href="https://wa.me/+998998335800" target="_blank"> +998 99 8335800 </a>  so that we will be aware about your booking.</p>

                                    <Request/>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Tashkenttour;