import React from 'react';
import {Link} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Tours extends React.Component {
    state= {
        responsive:{
            0: {
                items: 1,
                nav: true
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
    }

    render() {
        return (
            <div className="container">
            <div className="row">
                    <div className="col-xl-12">
                      <OwlCarousel
                                className="service_active owl-carousel owl-theme"
                                nav={false}
                                items={3}
                                loop={true}
                                margin={8}
                                autoplay ={false}
                                dots={false}
                                responsive={this.state.responsive}
                            >
                
                            <div className="single_service">
                                <div className="thumb">
                                    <img src="/assets/img/service/collage2.jpg" alt=""/>
                                </div>
                                <div className="service_info">
                                    <h3><Link to="/tashchimchartash">Tashkent – Chimgan – Charvak – Tashkent</Link></h3>
                                    <p>1 day</p>
                                </div>
                            </div>
                            <div className="single_service">
                                <div className="thumb">
                                    <img src="/assets/img/service/collage5.jpg" alt=""/>
                                </div>
                                <div className="service_info">
                                    <h3><a href="/samarkandtour">SAMARKAND</a></h3>
                                    {/* <p>1 Nights / 2 Days</p> */}
                                </div>
                            </div>
                            <div className="single_service">
                                <div className="thumb">
                                    <img src="/assets/img/service/collage7.jpg" alt=""/>
                                </div>
                                <div className="service_info">
                                    <h3><a href="/tashkenttour">Tashkent</a></h3>
                                    <p>Capital of Uzbekistan</p>
                                </div>
                            </div>
                     </OwlCarousel>


                    </div>
                </div>
                </div>
        )
    }
}

export default Tours;