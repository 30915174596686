import React from 'react';
import {useRef, useState} from "react";
import emailjs from '@emailjs/browser';

function Contact(){

    const form = useRef();
    const [done, setDone]=useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vbu7yvg', 'template_aow4gm7', form.current, 'NLb5ZR579PwIxz5hx')
            .then((result) => {
                console.log(result.text);
                setDone(true);
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
    };
        return(
            <div>
                <div className="bradcam_area bradcam_bg_1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="bradcam_text text-center">
                                    <h3>Contact Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-section">
                    <div className="container">

                        <div className="row">
                            <div className="col-12">
                                <h2 className="contact-title">Get in Touch</h2>
                            </div>
                            <div className="col-lg-8">
                                <form className="form-contact contact_form" ref={form} onSubmit={sendEmail}
                                      id="contactForm" noValidate="novalidate">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control w-100" name="message" id="message"
                                                          cols="30" rows="9" onFocus="this.placeholder = ''"
                                                          onBlur="this.placeholder = 'Enter Message'"
                                                          placeholder=" message"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control valid" name="name" id="name" type="text"
                                                       onFocus="this.placeholder = ''"
                                                       onBlur="this.placeholder = 'Enter your name'"
                                                       placeholder="Enter your name"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control valid" name="email" id="email"
                                                       type="email" onFocus="this.placeholder = ''"
                                                       onBlur="this.placeholder = 'Enter email address'"
                                                       placeholder="Email"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input className="form-control" name="subject" id="subject" type="text"
                                                       onFocus="this.placeholder = ''"
                                                       onBlur="this.placeholder = 'Enter Subject'"
                                                       placeholder="Enter Subject"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <button type="submit" className="button button-contactForm boxed-btn">Send
                                        </button>
                                    </div>
                                    <span>{done && "Thank you for contacting me!"}</span>
                                </form>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-home"></i></span>
                                    <div className="media-body">
                                        <h3>Tashkent, Uzbekistan.</h3>
                                        <p>8 Chinobod str., 100039</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                    <div className="media-body">
                                        <h3>+998 946702478</h3>
                                        <p>Mon to Fri 9am to 6pm</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-email"></i></span>
                                    <div className="media-body">
                                        <h3>info@stanadventure.com</h3>
                                        <p>Send us your query anytime!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )

}
export default Contact;