import React from 'react';
import {NavLink, Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Navbar, NavDropdown, NavItem} from 'react-bootstrap';
import NavbarToggle from "react-bootstrap/NavbarToggle";
import {LinkContainer} from 'react-router-bootstrap';


class Header extends React.Component{

    render() {
        return(
            <header>
                <div className="header-area ">
                    <div className="header-top_area d-none d-lg-block">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-4 col-lg-4">
                                    <div className="logo">
                                        <NavLink to="/">
                                            <img src="/assets/img/logo.jpg" alt="" width="60%" height="10%"/>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="sticky-header" className="main-header-area">
                        <div className="container">
                            <div className="header_bottom_border">
                                <div className="row ">
                                    <div className="col-12 d-block d-lg-none">
                                        <div className="logo">
                                            <Link to="/">
                                                <img src="/assets/img/logo.jpg" alt="" width="60%" height="10%"/>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-xl-9 col-lg-9">
                                            <Navbar bg="" expand="sm"   sticky="top"  collapseOnSelect >
                                                <Container>
                                                <Navbar.Brand href="/">
                                                    <i className="fa fa-home fa-lg text-light" aria-hidden="true" ></i>
                                                </Navbar.Brand>
                                                <NavbarToggle aria-controls="basic-navbar-nav" />
                                                <Navbar.Collapse id="basic-navbar-nav" >
                                                    <Nav className=" justify-content-end me-auto " variant="pills" activeKey="/">
                                                        <LinkContainer to="/blog">
                                                        <Nav.Link class="nav-links" >All about Uzbekistan</Nav.Link>
                                                        </LinkContainer>
                                                        <LinkContainer to="/tours">
                                                        <Nav.Link class="nav-links">Tours</Nav.Link>
                                                        </LinkContainer>
                                                     {/* <LinkContainer to="/group">
                                                        <Nav.Link class="nav-links">Group Packages</Nav.Link>
                                                        </LinkContainer> */}
                                                        <LinkContainer to="/travel">
                                                        <NavDropdown title="Travel Services" id="basic-nav-dropdown">
                                                            <NavDropdown.Item href="/transport">Transport services</NavDropdown.Item>
                                                            <NavDropdown.Item>Guide service</NavDropdown.Item>
                                                            <NavDropdown.Item>Train ticket</NavDropdown.Item>
                                                            <NavDropdown.Item>Others</NavDropdown.Item>
                                                        </NavDropdown>
                                                        </LinkContainer>
                                                        <LinkContainer to="/about">
                                                        <Nav.Link class="nav-links">About</Nav.Link>
                                                        </LinkContainer>
                                                        <LinkContainer to="/contact">
                                                        <Nav.Link class="nav-links" >Contact</Nav.Link>
                                                        </LinkContainer>
                                                    </Nav>
                                                </Navbar.Collapse>
                                                    </Container>
                                            </Navbar>


                                          {/*  <nav>
                                                <ul id="navigation">
                                                    <NavLink to={"/"}> <i className="fa fa-home fa-lg text-light" aria-hidden="true" ></i></NavLink>
                                                    <li><a href="single-blog.html"><i className="bi bi-house-heart"></i> &nbsp; &nbsp;&nbsp;&nbsp;All about Uzbekistan </a></li>
                                                    <li><a href="#">Tours</a></li>
                                                    <li><a href="">Group packages</a></li>
                                                    <li><a href="">Travel services <i className="ti-angle-down"></i></a>
                                                        <ul className="submenu">
                                                            <li><a href="">Airport transfer</a></li>
                                                            <li><a href="">Train ticket</a></li>
                                                            <li><a href="">Hotel booking</a></li>
                                                            <li><a href="">Guide service</a></li>
                                                        </ul>
                                                    </li>
                                                    <li><NavLink to="/about">About</NavLink></li>
                                                    <li><NavLink to="/contact">Contact</NavLink></li>
                                                </ul>
                                            </nav>*/}

                                    </div>
                                    <div className="col-12">
                                        <div className="mobile_menu d-block d-lg-none"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;