import React from 'react';
import {Link} from "react-router-dom";

class Tashkent extends React.Component{
    render() {
        return(
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/TAS/main.jpg" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Tashkent</a></li>
                                    </ul>
                                    <div className="blog_details">

                                        <p>

                                            <p>Tashkent is the capital of Uzbekistan. It is one of the largest cities in
                                                Central Asia. In terms of population, today it is one of the 5 largest
                                                cities in the Countries of Independent States. It is situated in the
                                                north-east part of the country in the valley of the Chirchik River. </p>

                                            <p>After the devastating earthquake in April 1966, almost nothing remained
                                                of the old part of the city. Today, many historical and architectural
                                                monuments have been restored and restored. Tashkent, like Uzbekistan as
                                                a whole, is very tolerant of completely different religious trends. Here
                                                you can see the proximity of the Catholic Cathedral to the madrasah and
                                                the Holy Dormition Orthodox Cathedral. </p>

                                            <p>Those who are tired of the bustle of the city are advised to go to the
                                                Charvak reservoir - an artificial reservoir surrounded by the mountains
                                                of the Western Tien Shan. Fans of diving, windsurfing, beach activities
                                                and water entertainment go to Charvak on vacation. The reservoir's
                                                beaches are equipped with sports grounds; for the convenience of
                                                vacationers, there is an opportunity to ride a catamaran, jet ski and
                                                aquabike. </p>
                                            <p>Active tourists will enjoy hiking in the natural parks of the city and
                                                its environs, mountain climbing and mountaineering. In winter, alpine
                                                skiing fans go to the resorts of the Chimgan Mountains. </p>
                                            When to go
                                            <p>Tashkent is located in the zone of moderate continental and subtropical
                                                climate. In summer, the temperature can rise to + 35–40 ℃. The summer
                                                period of calm heat is called "chilla". Winters in Tashkent are warm and
                                                short, the average air temperature is +4 ℃. </p>
                                            <p>In April, jazz fans and their travel companions can attend the
                                                International Jazz Festival in Uzbekistan. In early May, guests of the
                                                city will visit the Wedding Fest International Wedding Festival. </p>
                                            <p>In September, the city authorities are organizing a large-scale culinary
                                                festival. If a trip to Tashkent is planned for November, tourists have
                                                every chance to catch the PROlogue festival of short films of young
                                                Uzbek directors. </p>

                                            Sights
                                            <p>Amir Timur Square is the heart of Tashkent. There are many sights built
                                                around it that are worth seeing on vacation. Tourists will find the
                                                Timurid History Museum, the Forum Palace and the magnificent building of
                                                the Law Institute, built in the 19th century. The symbol of the city,
                                                the Tashkent chimes, is crowned with an amazingly beautiful
                                                architectural ensemble. </p>
                                            <p>The religious center of Tashkent is the Khast Imam square. It houses the
                                                cult religious building of the 16th century, the Barak-Khan madrasah and
                                                the largest operating mosque in Uzbekistan, Tilla-Sheikh. We recommend
                                                on vacation to pay attention to the building of the mausoleum of Saint
                                                Abu Bakr Kaffali Shashi, as well as to the Institute of Imams named
                                                after al-Bukhari. </p>
                                            <p>In the area of ​​the old Chorsu square, there is the only example of the
                                                Friday mosque in Tashkent - the Juma Mosque of Khoja Akhrar. Also, the
                                                Juma Mosque of Khoja Akhrar Vali is an example of a courtyard building
                                                widespread in the Middle Ages in the territory of Central Asia. </p>
                                            <p>The Sheikhantaur Ensemble is recognized as one of the main architectural
                                                monuments of Tashkent. The central building of the Sheikhantaur Ensemble
                                                is the mausoleum of Sheikh Havendi Takhur. </p>
                                            <p>Those who prefer an educational program on a trip are advised to visit
                                                the museums of Tashkent. World famous exhibits are kept in the
                                                Historical Museum of Uzbekistan. You can get acquainted with the
                                                decorative and applied arts of the peoples of Uzbekistan in the
                                                State.</p>
                                            Museum of Arts.
                                            <p>Also, special attention deserves the Republican Opera and Ballet Theater.
                                                Navoi, as well as the area around it. The second tallest building in
                                                Central Asia is the Tashkent TV Tower.</p>
                                            Shopping and entertainment
                                            <p>A trip to the old Chorsu bazaar will allow vacationers to appreciate the
                                                real oriental flavor. To get a mutual pleasure from shopping, do not
                                                forget to bargain.</p>
                                            <p>There are shopping and entertainment centers in the central part of the
                                                city. Some of the city's largest trading platforms are Compass,
                                                Samarkand Darvoza, Next, Mega Planet and Eski shahar. </p>
                                            <p>Those travelers who come on vacation with children should plan a trip to
                                                the Solnechny Gorod entertainment complex. A large number of attractions
                                                are installed in the parks "Ashgabat", "Lokomotiv-Ankhor", "Central
                                                City". In the center of the city, the Tashkent Zoo is spread over 23
                                                hectares of land. Creative workshops, halls for practicing various
                                                sports, exhibitions and conference rooms - all this is in the Tashkent
                                                Palace of Youth Creativity</p>

                                        </p>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Tashkent;