import React from 'react';

class Footer extends React.Component{
    getYear() {
        return new Date().getFullYear();
    }
    render() {
        return(

            <footer className="footer">
                <div className="contact_location">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="location_left">
                                    <div className="logo">
                                        <a href="/">
                                            <img className="w-50 h-25"  src="/assets/img/logo1.png" alt=""/>
                                        </a>
                                    </div>
                                    <ul>
                                        <li><a href="https://www.instagram.com/stanadventure/" target="_blank"> <i
                                            className="fa fa-instagram"></i> </a></li>
                                              <li><a href="https://www.tripadvisor.com/ShowUserReviews-g293968-d25278996-r891689645-StanAdventure-Tashkent_Tashkent_Province.html" target="_blank"> <i className="fa fa-tripadvisor"></i> </a></li>
                                        <li><a href="https://uz.linkedin.com/company/stanadventure" target="_blank">  <i className="fa fa-linkedin"></i> </a></li>
                                        <li><a href="https://api.whatsapp.com/send/?phone=%2B998946702478&text&type=phone_number&app_absent=0" target="_blank"> <i className="fa fa-whatsapp"></i> </a></li>
                                        <li><a href="mailto:info@stanadventure.com" target="_blank"> <i className="fa fa-envelope"></i> </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3 p-1">
                                <div className="single_location">
                                    <h3><img src="/assets/img/icon/address.svg" alt=""/> Location</h3>
                                    <p>8 Chinobod st., Tashkent, Uzbekistan, 100039</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3">
                                <div className="single_location">
                                    <h3><img src="/assets/img/icon/support.svg" alt=""/> Contact</h3>
                                    <p>
                                        <a href="tel:998946702478"> +998946702478</a> <br/>
                                        <a href="mailto: info@stanadventure.com">info@stanadventure.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right_text">
                    <div className="container">
                        <div className="footer_border"></div>
                        <div className="row">
                            <div className="col-xl-12">
                                <p className="copy_right text-center">
                                    Copyright &copy; 2017-{this.getYear()} All rights reserved | This website is made with 💙  by
                                    <a href="https://stanadventure.com" target="_blank"> StanAdventure</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;