import React, { useEffect, useState } from "react";
import './request.css';
import Swal from 'sweetalert2';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Request =()=>{


    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        fetch(
          "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
          .then((response) => response.json())
          .then((data) => {
            setCountries(data.countries);
            setSelectedCountry(data.userSelectValue);
          });
      }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "cc868315-9baa-4be0-8774-db3fe4434c2c");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
            Swal.fire({
                title: "Success!",
                text: "Message sent succcessfully!",
                icon: "success"
              }).then(function(){
                window.location.reload();
            });
              
        }
      };

    return(
        <section className="request">
            <form onSubmit={onSubmit}> 
            <h2>Tour Request Form</h2>  

                <div className="row input-box">
                <div class="col-sm">
                    <label>First Name</label>
                <input tyoe="text" className="field" placeholder="First Name" name="firstName" required></input>
                </div>
              
                <div className="col-sm">
                <label>Last Name</label>
                <input tyoe="text" className="field" placeholder="Last Name" name="lastName"   required></input>
                    </div>                                                                                                                                 
              </div> 
                <div className="input-box">
                 <label>Citizenship</label>
            
                <Select
      options={countries}
      value={selectedCountry}
      name="Citizenship"
      onChange={(selectedOption) => setSelectedCountry(selectedOption)}
    />
                </div>   
               
                <div className="input-box">
                <label>Email</label>
                <input tyoe="email" className="field" placeholder="Email" name="email"  required></input>
                </div>   

                <div className="row input-box">
                <div class="col-sm">
                <label>Start date</label>
                <input type="date" className="field" placeholder="Choose Start date" name="startDate"  required></input>
                </div>
                <div class="col-sm">
                    <label>Number of travelers</label>
                <input type="number" className="field" placeholder="Number of travelers" name="travelers"  required></input>
                </div>
                </div>  

                <div className="input-box">
                <label>Comments:</label>
                <textarea name="message"  className="field mess"></textarea>
                </div>   
            
                <button type="submit">Send request</button>
            </form>         
        </section>
    )
}

export default Request;