import React from "react";
import {Link} from "react-router-dom";
import Request from "../components/Request/request";

class Tashchimchartash extends React.Component{
    render() {
        return(
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/tash_chim.png" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Tashkent – Chimgan – Charvak – Tashkent</a></li>
                                    </ul>
                                    <div className="blog_details">

                                        <p>
                                            <p>On that beautiful day we will be exploring Uzbekistan’s wonderful and spectacular Chimgan and Charvak Mountains. </p>
                                            <p>09:00 – 10:30 - Transfer to Chimgan mountains, 120 km from Tashkent. En-route enjoy the picturesque views of beautiful mountains and the life of local people. 
                                                Chimgan Mounts offer many seasonal outdoor activities like skiing, snowboarding, climbing, horse-riding, quadricycle riding, paragliding. </p>
                                            <p>First, we will arrive in Amirsoy resort to ride the cable car. After that, we will continue driving (20 mins.) to Charvak lake and stop in an 
                                                area where there is a panoramic view of the lake and the mountains. There you may do a horseback riding and/or rent a quadricycle and you will have a chance to do paragliding.</p>
                                           
                                            <p>Drive back to Tashkent at around 15:00 and arrive by 16:30.</p>
                                            <div className="quote-wrapper">
                                            <div className="quotes">
                                                <strong> *Rates for optional activities:  </strong><br/><br/>
                                                <strong> Amirsoy Cable car -</strong> $17-$20 <br/>
                                                <strong> Horseback riding between-</strong> $10-18 for 30-60 minutes <br/>
                                                <strong> Quadricycle between -</strong> $10-18 for 10-30 minutes <br/>
                                                <strong> Paragliding- </strong> $53 for 10 minutes (GoPro camera will be given for free to rent) <br/><br/>
                                               
                                                <b> Total rate: $80 </b><br/><br/>
                                                <strong> The tour includes: </strong> - Transportation - Sedan car (Chevrolet Cobalt or similar)<br/>
                                               
                                            </div>
                                        </div>


                                        </p>
                                        <Request/>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Tashchimchartash;