import React from 'react';
import {Link} from "react-router-dom";

class Bukhara extends React.Component{
    render() {
        return(
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/BUK/main.jpg" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Bukhara</a></li>
                                    </ul>
                                    <div className="blog_details">

                                        <p>
                                            <p>Bukhara is a city located on the southeast side of Uzbekistan. Bukhara is
                                                one of the most ancient settlements in Central Asia. Archaeological
                                                research and artifacts allow the city to be dated to the 4th century.
                                                BC. The route of the Great Silk Road ran through Bukhara. </p>
                                            <p>There are more than 150 architectural monuments dating back to the Middle
                                                Ages on the territory of Bukhara. Since 1993, Bukhara has been included
                                                in the UNESCO cultural heritage list.</p>
                                            When to go
                                            <p>Bukhara has a sharply continental climate. It is very hot in summer. The
                                                temperature fluctuates + 32 + 35 ° C. In winter, the temperature reaches
                                                + 4 + 6 ° C. Temperatures may go down to minus mark at night. It rains
                                                from December to April. The best time to travel to Bukhara is considered
                                                to be the off-season.</p>
                                            Sights
                                            <p>You should start your trip around Bukhara with a visit to the Samanid
                                                mausoleum, an architectural masterpiece of Central Asia. This ancient
                                                mausoleum was built during the 11th century. Inside the Mausoleum there
                                                are 3 bodies, one of which, scientists were able to identify as the body
                                                of the son of the emir of the Samanid dynasty Ahmad ibn Ismail.</p>
                                            <p>The citadel of the ARC. Another no less ancient architectural and
                                                archaeological monument of the city. It is located on Registan Square,
                                                in the Old City. There is an opinion that in the Middle Ages the famous
                                                poet Omar Khayyam lived here. Today, on the territory of the citadel
                                                there is the State Architectural and Art Museum-Reserve of Bukhara. </p>
                                            <p>Poi-Kalyan architectural complex. The architectural ensemble, which is
                                                definitely worth seeing, includes the Kalyan minaret, the Kalyan mosque,
                                                as well as the Miri Arab madrasah.</p>
                                            <p>Kosh madrasah. It is located in the west of the old city and was built by
                                                order of Abdullah Khan in the 16th century. The Kosh Madrasah consists
                                                of 2 madrassas: Modari Khan Madrasah and Abdullah Khan Madrasah </p>
                                            <p>Kukeldash Madrasah. The building is considered the largest religious
                                                educational institution in Central Asia. Kukeldash is part of the
                                                Lyabi-Haus architectural and park ensemble, along with two other
                                                buildings: the Nadir Divan-Begi madrasah and the Nadir Divan-Begi
                                                khanaki.</p>
                                            <p>Madrasah of Abdulaziz Khan. The walls of the building are decorated with
                                                genre drawings in the form of dragons and birds, reminiscent of
                                                paintings from India and China.</p>
                                            <p>Magoki-Attari Mosque. This mosque is located in the center of Bukhara and
                                                is almost 4 meters below ground level. Currently, it houses the Museum
                                                of Carpet Making.</p>
                                            <p>Chor-Bakr necropolis - In the village of Sumitan, not far from Bukhara,
                                                there is a place of pilgrimage for Muslims, the Chor-Bakr necropolis. It
                                                is a "city of the dead" with courtyards, a mosque, streets and gates
                                                that lead to the family dakhmas and tombstones. The grave of Khoja Abu
                                                Bakr Sad, an adviser to one of the rulers of Bukhara, is considered the
                                                central burial of the necropolis.</p>
                                            Shopping and entertainment
                                            <p>Trading dome Taki-Sarrafon. Here, in ancient times, money was changed and
                                                books were sold, today in this place you can buy various goods: clothes,
                                                shoes and jewelry. </p>
                                            <p>The Telpak-Furushon trading dome sold hats. Since then, nothing has
                                                changed. </p>
                                            <p>The large trading dome of Taki-Zargaron was the dome of jewelers. Here
                                                they traded exclusively in gold jewelry. Today, there are about 40 shops
                                                inside the bazaar. There are ateliers and warehouses. </p>
                                            <p>The most ambitious cultural event of the city is considered the festival
                                                "Silk and Spices", which takes place in Bukhara from May 28 to June 1.
                                                As part of the holiday, stalls with various authentic goods are set up
                                                on the streets of the Old Town. The festival is opened by artists at the
                                                Ark fortress. For several days, tourists and their fellow travelers can
                                                attend concerts, theatrical performances and master classes of artisans
                                                from all over Uzbekistan.</p>

                                        </p>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Bukhara;