import React from "react";
import {Link} from "react-router-dom";

class Shakhrisabz extends React.Component{
    render() {
        return(
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/main.jpeg" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Shakhrisabz</a></li>
                                    </ul>
                                    <div className="blog_details">

                                        <p>
                                            <p>Shakhrisabz is a city in the Kashkadarya region of Uzbekistan. In 2000,
                                                the historical center of the city and its archaeological, architectural,
                                                religious and cultural monuments were included in the UNESCO World
                                                Heritage List under the name "Historical Center of Shakhrisabz".</p>
                                            <p>The name of the city of Shakhrisabz comes from the Persian language and
                                                translates as the Green City. This name has been stuck with the city
                                                since the XIV century. Prior to this, the city was called and known as
                                                Kesh.</p>
                                            <p>Shakhrisabz is located in the valley of the same name, which is
                                                surrounded from the north, south and east by the Zarafshan ridge - part
                                                of the Gissar-Alai mountain system. In the west, the Shakhrisabz valley,
                                                which is sometimes called an oasis, adjoins the Kashkadarya oasis, where
                                                the main cities of the Kashkadarya wilayat are located.</p>
                                            When to go
                                            <p>The climate in Shakhrisabz is subtropical inland. The maximum summer
                                                temperatures can reach +40 ° C. Winter in Shakhrisabz is mild with rare
                                                subzero temperatures and snowfalls. The most comfortable time to stay in
                                                the city is considered to be the off-season period.</p>
                                            Sights
                                            <p>The most famous monuments of Shakhrisabz:</p>
                                            <p>Aksaray. The grandiose ruined residence of Timur in his hometown of
                                                Shakhrisabz.</p>
                                            <p>Memorial complex Dorut Tilavat, which includes:</p>
                                            <p>1. Mausoleum of Sheikh Shamsad-Din Kulyal al-Keshi (Timur's father Amir
                                                Turagay is also buried there);</p>
                                            <p>2. Mausoleum of Gumbazi-Seyidan (Dome of the Seyids);</p>
                                            <p>3. Kok-Gumbaz Mosque (Blue Dome);</p>
                                            <p>4. Remains of the Timurid dynastic tomb Dorus-Siadat, including Timur's
                                                crypt.</p>

                                        </p>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Shakhrisabz;