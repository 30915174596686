import React from 'react';
import {Link} from "react-router-dom";


class Blog extends React.Component {
    render() {
        return (
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/banner/banner.jpg" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Registan Square, Samarkand</a>
                                        </li>

                                    </ul>
                                    <div className="blog_details">
                                        <h2>Uzbekistan travel guide </h2>

                                        <div className="quote-wrapper">
                                            <div className="quotes">
                                                <strong> General information about Uzbekistan </strong><br/><br/>
                                                <strong> Geographical location:</strong> Central Asia <br/>
                                                <strong> Area:</strong> 447.4 thousand sq. km. <br/>
                                                <strong> Population:</strong> 34 million people<br/>
                                                <strong> Capital:</strong> Tashkent <br/><strong> Languages: </strong>Uzbek
                                                (state language), Russian, Tajik <br/><strong> Religion:</strong> Islam -
                                                    89%, Christianity - 8%, other religions -
                                                    3% <br/><strong> Electricity: </strong>220V / 50Hz; standard double
                                                        plug socket. <br/><strong> Time zone: </strong>+ 5
                                                            hours <br/><strong> Internet
                                                                zone: </strong>.UZ <br/><strong> International dialing
                                                                code: </strong>+998 <br/><strong> Monetary
                                                                unit: </strong>Sum<br/>
                                                                <strong> Climate:</strong> Winters are mild, summers are
                                                                hot
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>

                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default Blog;