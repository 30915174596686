import React from 'react';
import {Link} from "react-router-dom";

class Khiva extends React.Component{
    render() {
        return (
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/KHI/main.jpg" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Khiva</a></li>
                                    </ul>
                                    <div className="blog_details">

                                        <p>
                                            <p> Khiva is one of the oldest cities in Uzbekistan and throughout Central
                                                Asia. The city is located in the south-west of the central part of the
                                                country, not far from the border with Turkmenistan. Khiva has a large
                                                number of ancient monuments of history and architecture. The historical
                                                center of Khiva has been included in the UNESCO World Heritage List
                                                since 1990 as an integral and well-preserved complex of Islamic
                                                architecture in Central Asia.</p>
                                            <p>Khiva is one of the main cities of the Great Silk Road. All trade
                                                caravans that transported goods between China and Europe in ancient
                                                times and in the Middle Ages, by all means made a stop in this city.
                                                Thanks to trade routes, Khiva developed and flourished. At the end of
                                                the 16th century, the city became the capital of the Khiva Khanate and
                                                quickly turned into a major educational center of the Islamic world.
                                            </p>
                                            <p>The most interesting tourist sites are the old city of Ichan-Kala, on the
                                                territory of which the Kunya-Ark Citadel, the Tash-Khovli palace,
                                                several madrasahs, mosques and mausoleums are located. </p>
                                            When to go
                                            <p>Khiva is located in a zone of arid, sharply continental climate. The
                                                desert, which surrounds the city on all sides, has a significant
                                                influence on the weather. The most favorable time to travel to this part
                                                of Uzbekistan is spring (April and May), as well as autumn (September
                                                and October). During these periods, the temperature is favorable for
                                                sightseeing, as the thermometer stays between +20 ° C and +25 ° C during
                                                the day. The most unfortunate time for a vacation in Khiva is considered
                                                to be the period from late June to early August. During these months, an
                                                exhausting heat sets in, sometimes exceeding +40 ° C. Winter is also
                                                unfavorable for excursions, as frosts down to -10 ° C often occur,
                                                accompanied by strong cold winds. </p>
                                            <p>A visit to Khiva can be timed to coincide with the spring holiday
                                                "Navruz", which is celebrated on March 21st. </p>
                                            <p>In early August, Khiva hosts a festival-fair "Gurvak - a symbol of melons
                                                of Khorezm", within the framework of which performances of creative
                                                groups are held. </p>
                                            Sights
                                            <p>The old city of Ichan-Kala. Most of its buildings were built in the 19th
                                                century and only a few belong to earlier periods. The complex has a
                                                complete harmonious appearance, which is of great historical and
                                                aesthetic value, for which it was included in the UNESCO World Heritage
                                                List. </p>
                                            <p>The walls of the ancient city, especially the Eastern gate of
                                                Palvan-Darvaza and the Akshi-Bobo bastion, draw attention to themselves.
                                                The Kunya-Ark Citadel is located inside the fortress walls. Completed in
                                                the 17th century, it played the role of a palace and residence of the
                                                ruler of Khiva until the construction of a new palace, Tash-Khovli, in
                                                the 19th century. </p>
                                            <p>When visiting Khiva, we recommend seeing the traditional Islamic
                                                educational institutions that once glorified the city: the madrasahs of
                                                Muhammad Amin Khan, Muhammad Rahim Khan II, Alla Kuli Khan and Shirgazi
                                                Khan.</p>
                                            <p>There are several mosques and minarets on the territory of the complex,
                                                which have become one of the symbols of the old city. The most popular
                                                minaret is Kalta-Minor. Slightly less known are the Islam Khoja complex
                                                and the Friday Mosque.</p>
                                            <p>We also recommend taking a look at the mausoleums of Khiva: Pakhlavan
                                                Mahmud and Said Allauddin.</p>
                                            <p>Do not forget to visit the Baths of Anush Khan, dating back to the 17th
                                                century, as well as the covered tim market and the Allakulikhan
                                                caravanserai, built in the nineteenth century.</p>
                                            <p>A trip to the Khiva Museum of Crafts will be informative, which contains
                                                a rich collection of exhibits telling about the life and occupations of
                                                the townspeople in the 19th - early 20th centuries. A trip to an earlier
                                                period in the history of the city and the entire region can be made by
                                                visiting the Museum of the History of Khorezm named after Al-Khorezmi
                                                and Beruni, which contains archaeological finds from the settlements of
                                                the 6th-3rd centuries AD. e.</p>
                                            Shopping and entertainment
                                            <p>Khiva is one of the major tourist centers and has many souvenir shops and
                                                shops in its arsenal, which are located in the old city of
                                                Ichan-Kala.</p>
                                            <p>You should definitely visit the city bazaar located near the
                                                Palvan-Darvaza gate. This market sells local fruits, oriental sweets, as
                                                well as silk and cotton products at fairly low prices. </p>
                                            <p>In the vicinity of the city, there is the Badai Tugai nature reserve,
                                                inhabited by rare species of animals and birds.</p>

                                        </p>
                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default Khiva;