import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'normalize.css/normalize.css';


const content = [
    {
        title: 'Samarkand, Uzbekistan',
        description:
            'Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.',
        button: 'Read More',
        image: '/assets/img/banner/1.jpg',
        user: 'Luan Gjokaj',
        userProfile: 'https://i.imgur.com/JSW6mEk.png'
    },
    {
        title: 'Bukhara, Uzbekistan',
        description:
            'Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.',
        button: 'Discover',
        image: '/assets/img/banner/2.jpg',
        user: 'Erich Behrens',
        userProfile: 'https://i.imgur.com/0Clfnu7.png'
    },
    {
        title: 'Khiva, Uzbekistan',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.',
        button: 'Buy now',
        image: '/assets/img/banner/3.jpg',
        user: 'Bruno Vizovskyy',
        userProfile: 'https://i.imgur.com/4KeKvtH.png'
    }
];

const Sliders = () => (
    <div>

{/*        <Slider classNames={horizontalCss} autoplay={3000}>
            {content.map((item, index) => (
                <div
                    key={index}

                    style={{ background: `url('${item.image}') no-repeat center center` }}
                >
                    <div className="center">
                        <h1>{item.title}</h1>
                        <p>{item.description}</p>
                        <button>{item.button}</button>
                    </div>
                </div>
            ))}
        </Slider>*/}



        <Carousel interval={3000} fade>
            {content.map((item, index) => (
            <Carousel.Item key={index} >

                <img
                    className="mybanner d-block w-100 img-fluid min-vh-10"
                    src={item.image}
                    alt="First slide"
                    loading="lazy"
                />
                <Carousel.Caption>
                    <h3 className="text-light">{item.title}</h3>
                </Carousel.Caption>
            </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default Sliders;