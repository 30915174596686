import React from 'react';
import Home from './pages/home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";

import Contact from "./pages/contact";
import "./App.css";
import Blog from "./pages/single_blog";
import Tours from './pages/tours';
import Tashkent from "./pages/tashkent";
import Bukhara from "./pages/bukhara";
import Khiva from "./pages/khiva";
import Samarkand from "./pages/samarkand";
import Shakhrisabz from "./pages/shakhrisabz";
import Tashchimchartash from './pages/tashchimchartash';
import Samarkandtour from './pages/samarkandtour';
import Tashkenttour from './pages/tashkenttour';

function App() {
  return (

    <div className="App">
    <Router>
        <Header/>
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route  path="/blog" element={<Blog/>} />
            <Route  path="/tours" element={<Tours/>} />
            <Route  path="/tashkent" element={<Tashkent/>} />
            <Route  path="/bukhara" element={<Bukhara/>} />
            <Route  path="/khiva" element={<Khiva/>} />
            <Route  path="/samarkand" element={<Samarkand/>} />
            <Route  path="/shakhrisabz" element={<Shakhrisabz/>} />
            <Route  path="/contact" element={<Contact/>} />
            <Route  path="/tashchimchartash" element={<Tashchimchartash/>} />
            <Route  path="/samarkandtour" element={<Samarkandtour/>} />
            <Route path='/tashkenttour' element={<Tashkenttour />} />

        </Routes>
        <Footer/>
    </Router>
    </div>

  );
}

export default App;
