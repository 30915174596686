import React from "react";
import {Link} from "react-router-dom";

class Samarkand extends React.Component{
    render() {
        return(
            <div>
                <section className="blog_area single-post-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 posts-list">
                                <div className="single-post">

                                    <div className="feature-img">
                                        <img className="img-fluid" src="/assets/img/SAM/main.jpg" alt=""/>
                                    </div>
                                    <ul className="blog-info-link mt-3 mb-4">
                                        <li><a href="#"><i className="fa fa-camera"></i> Samarkand</a></li>

                                    </ul>
                                    <div className="blog_details">

                                        <p>
                                            <p> Samarkand is the administrative center of the Samarkand region and the
                                                second largest city in Uzbekistan. It is located in the southeastern
                                                part of the country in the valley of the Zarafshan River.</p>
                                            <p>Samarkand is perfect for those who appreciate historical and
                                                architectural monuments. After founding, in the 7th century BC. BC,
                                                Samarkand was for a long time the center of intersection of various
                                                cultures and civilizations. For more than two millennia Samarkand was
                                                one of the important points of the Great Silk Road. Samarkand since
                                                2001, included in the UNESCO World Heritage List.</p>
                                            <p>Most of the architectural monuments of Samarkand have been preserved
                                                since the reign of the Timurids (mid-14th - late 15th centuries). </p>
                                            <p>Visiting Samarkand, you can see the following architectural monuments
                                                that have survived to this day: three madrasahs on Registan Square
                                                (Ulugbek, Sher-Dor and Tillya-Kari madrasahs), the Shahi-Zinda mausoleum
                                                complex, the Gur-Emir Mausoleum, the Bibi-Khanym Mosque and the
                                                Observatory Mirzo Ulugbek.</p>
                                            When to go
                                            <p>The climate of Samarkand is continental-subtropical. Summer is sultry,
                                                the air temperature can reach +30 ° C. In winter, the air temperature is
                                                around +3 ° C. </p>
                                            Sights
                                            <p>Registan Square is one of the most beautiful squares in the world. She is
                                                the personification of the greatness of the ancient architects of the
                                                east. The square consists of three magnificent monuments of ancient
                                                architecture: Ulugbek madrasah, Sher-Dor madrasah and Tillya-Kari
                                                madrasah. These madrasahs in ancient times served as educational
                                                institutions. All three monuments are decorated with unique mosaics.</p>
                                            <p>Gur-Emir is the tomb of Amir Timur and his heirs. During the construction
                                                of the mausoleum, the prototype of the famous Taj Mahal mausoleum in
                                                Agra was taken as a basis. Not far from Gur-Emir is the Rukhabad
                                                mausoleum, built over the grave of Sheikh Burkhaneddin Sagaraja.
                                            </p>
                                            <p>Bibi-Khanym Mosque. The mosque, according to the legend, was built by
                                                Amir Timur and named after his beloved wife. </p>
                                            <p>Ensemble Shakhi-Zinda. The complex serves as a burial place for crowned
                                                persons. It consists of 11 tombs. Legend has it that in the main
                                                mausoleum was buried a cousin of the Prophet Muhammad - Kusam Ibn-Abbas,
                                                who was called the "Living King" or "Shahi Zinda"</p>
                                            <p>The settlement Afrasiab (VII-II centuries BC). According to legend,
                                                earlier on this place was a huge city founded by the king of Turan,
                                                Afrosiab. On the outskirts of the settlement, the mausoleum of the
                                                prophet St. Daniel has been preserved. </p>
                                            <p>Ulugbek Observatory, (XV century), will interest those who are fond of
                                                the history of science.</p>
                                            Shopping and entertainment
                                            <p>If, as a result of your trip to Samarkand, you want to take with you the
                                                best samples of products made by Samarkand artisans, as well as clothes
                                                in ethnic style from leading designers of Uzbekistan, then you need to
                                                visit the Aysel art studio. In the art gallery "Happy Bird" you can buy
                                                handmade carpets, national clothes, ceramics and wood products.</p>
                                            <p>Those who want to feel the atmosphere of the oriental market will be
                                                interested in visiting the bazaar in the historic center of the city. At
                                                the central market of Samarkand, they will be able to buy spices, dried
                                                fruits, national souvenirs and get a lot of positive emotions. </p>
                                            <p>Travelers who want to bring an oriental carpet from their vacation are
                                                recommended to visit the Khujum silk carpet factory. At the factory, you
                                                can not only purchase one of the amazing carpets, but also see the
                                                process of making it.</p>

                                        </p>

                                    </div>
                                </div>
                                <div className="navigation-top">

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="blog_right_sidebar">

                                    <aside className="single_sidebar_widget post_category_widget">
                                        <h4 className="widget_title">Uzbekistan Sights</h4>
                                        <ul className="list cat-list">
                                            <li>
                                                <Link to="/tashkent" className="d-flex">
                                                    <p>Tashkent</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/samarkand" className="d-flex">
                                                    <p>Samarkand</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/bukhara" className="d-flex">
                                                    <p>Bukhara</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/khiva" className="d-flex">
                                                    <p>Khiva</p>

                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shakhrisabz" className="d-flex">
                                                    <p>Shakhrisabz</p>

                                                </Link>
                                            </li>

                                        </ul>
                                    </aside>

                                    <aside className="single_sidebar_widget tag_cloud_widget">
                                        <h3 className="widget_title">General info</h3>
                                        <ul className="list">
                                            <li>
                                                <a href="#">archeology</a>
                                            </li>
                                            <li>
                                                <a href="#">arts and crafts</a>
                                            </li>
                                            <li>
                                                <a href="#">bazaars</a>
                                            </li>
                                            <li>
                                                <a href="#">border crossings</a>
                                            </li>
                                            <li>
                                                <a href="#">cellular</a>
                                            </li>
                                            <li>
                                                <a href="#">country profile</a>
                                            </li>
                                            <li>
                                                <a href="#">customs rules</a>
                                            </li>
                                            <li>
                                                <a href="#">distances</a>
                                            </li>
                                        </ul>
                                    </aside>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Samarkand;