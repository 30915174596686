import React from 'react';
import {useRef, useState} from "react";
import Sliders from './slider';
import emailjs from '@emailjs/browser';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';


function Home() {
    const form = useRef();
    const [done, setDone]=useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vbu7yvg', 'template_aow4gm7', form.current, 'NLb5ZR579PwIxz5hx')
            .then((result) => {
                console.log(result.text);
                setDone(true);
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div>


            <Sliders/>

       <div className="transportaion_area">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="single_transport">
                            <div className="icon">
                                <img src="/assets/img/svg_icon/airplane.png" alt=""/>
                            </div>
                            <h3>Discover the best of Uzbekistan</h3>
                            <p >
                                <br/>
                                    &nbsp;&nbsp;Uzbekistan is a must-visit travel destination for people who want to learn
                                about the rich history and culture of Centra Asia tis the petect pace to travel & eyplore the
                                fascinating history of the Silk Road trade through the well-preserved architecture of ancient cities
                                like Khiva, SamarKand and Bukhara.
                                    <br/><br/>

                                        &nbsp;Welcome to a land of stately architecture and ancient traditions that have
                                        preserved a unique heritage. Every guest is welcome here.
                                        <br/><br/>
                                            &nbsp;It is a warm, hospitable country with a thousand-year history
                                            and an insane hospitality of the local people. It's cozy, tasty and safe
                                            here.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img className="d-block w-100 h-100" src="/assets/img/about/main.jpg" alt="main_pic" loading="lazy"/>
                    </div>


                </div>
            </div>
        </div>



        <div className="service_area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section_title mb-50 text-center">
                            <h3>
                                Travel with StanAdventure
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                      <OwlCarousel
                                className="service_active owl-carousel owl-theme"
                                nav={false}
                                items={3}
                                loop={true}
                                margin={8}
                                autoplay ={false}
                                dots={false}
                            >
                
                            <div className="single_service">
                                <div className="thumb">
                                    <img src="/assets/img/service/collage2.jpg" alt=""/>
                                </div>
                                <div className="service_info">
                                    <h3><Link to="/tashchimchartash">Tashkent – Chimgan – Charvak – Tashkent</Link></h3>
                                    <p>1 day</p>
                                </div>
                            </div>
                            <div className="single_service">
                                <div className="thumb">
                                    <img src="/assets/img/service/collage5.jpg" alt=""/>
                                </div>
                                <div className="service_info">
                                    <h3><a href="/samarkandtour">SAMARKAND</a></h3>
                                    {/* <p>1 Nights / 2 Days</p> */}
                                </div>
                            </div>
                            <div className="single_service">
                                <div className="thumb">
                                    <img src="/assets/img/service/collage7.jpg" alt=""/>
                                </div>
                                <div className="service_info">
                                    <h3><a href="/tashkenttour">Tashkent</a></h3>
                                    <p>Capital of Uzbekistan</p>
                                </div>
                            </div>
                     </OwlCarousel>


                    </div>
                </div>
            </div>
        </div>

        <div className="Estimate_area overlay">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-5">
                        <div className="Estimate_info">
                            <h3>Start your adventure...</h3>
                            <p>Leave your tour details, our consultant contact with you within 24hour.</p>
                            <a href="tel:998946702478" className="boxed-btn3">+998 94 6702478</a>
                        </div>
                    </div>


                  {/*   <div className="col-xl-8 col-lg-8 col-md-7">
                        <div className="form">
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="input_field">
                                            <input type="text" name="name" placeholder="Your name" required/>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="input_field">
                                            <input type="email" name="email" placeholder="Email" required/>
                                        </div>
                                    </div>

                                    <div className="col-xl-12">
                                        <div className="input_field">
                                            <textarea placeholder="Message" name="message" required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="input_field ">
                                            <button type="submit" className="btn btn-primary"> Send</button>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-light">{done && "Thank you for contacting me!"}</span>
                            </form>
                        </div>
                    </div>*/} 
                </div>
            </div>
        </div>


        <div className="modal fade custom_search_pop" id="exampleModalCenter" tabIndex="-1" role="dialog"
             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="serch_form">
                        <input type="text" placeholder="search"/>
                            <button type="submit">search</button>
                    </div>
                </div>
            </div>
        </div>

        </div>
    )
}

export default Home;